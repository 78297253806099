

export function WhyChooseUs() {
    return (
        <div>
            <div className="bg-custom-background-white flex flex-col mx-[10%] relative ">
                <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-2xl text-custom-orange text-center self-start">
                    WHY CHOOSE US
                </p>

                <div className='flex sm:flex-row flex-col w-full mt-8'>
                    <div className="flex flex-col border-t-2 sm:w-1/2 w-full">
                        <div className="flex flex-row py-6 justify-between ">
                            <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                    01
                                </p>
                                <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                        Unique designs
                                    </p>
                                    <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                        For every client we create a customised website or social media account visuals to reflect your personality and stand out of the competitive market.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-col border-t-2 sm:w-1/2 w-full">
                        <div className="flex flex-row py-6 justify-between ">
                            <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                    02
                                </p>
                                <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                        Continuous support
                                    </p>
                                    <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                        We are available for ongoing services and maintenance as long as you need to secure the best results.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex sm:flex-row flex-col w-full'>
                <div className="flex flex-col border-t-2 sm:w-1/2 w-full">
                        <div className="flex flex-row py-6 justify-between ">
                            <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                    03
                                </p>
                                <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                        Keeping up with trends
                                    </p>
                                    <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                        To guarantee satisfying results, we stay up-to-date with the latest trends and technologies.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-col border-t-2 sm:w-1/2 w-full">
                        <div className="flex flex-row py-6 justify-between ">
                            <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                    04
                                </p>
                                <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                        Multi-language
                                    </p>
                                    <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                        Our team is international. We speak English, French, Russian, Turkish and Dutch languages.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}