
import WMSupernovaDigital from '../assets/WMSupernovaDigital.svg';
import Orange1 from '../assets/Ellipse1.png';
import Orange2 from '../assets/Ellipse2.png';
import Orange3 from '../assets/Ellipse3.png';

export function HomeScreen() {
    return (
        <div>
            <div className='bg-custom-background-white h-[90vh]'>
                <div className='flex flex-col items-center '>
                    <img src={WMSupernovaDigital} alt="website marketing" className='lg:pt-[5%] xs:pt-[15%] pt-[30%] w-[900px] rounded-none' />
                    <p className='tracking-wide font-custom-m font-light xs:text-2xl text-1xl text-custom-black lg:mb-[2%] lg:mt-[0%] xs:mt-[5%] xs:mb-[7%] mt-[7%] mb-[7%] text-center xs:mx-[0] mx-[5%]'>Innovative <span className='font-custom-r'> website</span> design and development <br /> paired with strategic <span className='font-custom-r'>marketing</span></p>
                    <button className='text-1xl text-custom-bright-white bg-custom-orange rounded-button w-[200px] md:py-[16px] py-[10px] xs:mx-[0] mx-[8%] z-[50]'><a href='#contact'>GET IN TOUCH</a></button>
                </div>
                    <img src={Orange1} alt="orange" className='w-[500px] absolute z-[2] top-[30%] lg:right-[80%] right-[70%]' />
                    <img src={Orange2} alt="orange" className='w-[400px] absolute z-[2] top-[75%] right-[10%]' />
                    <img src={Orange3} alt="orange" className='w-[300px] absolute z-[2] top-[0%] right-[0%]' />
            </div>
        </div>
    );
}