
import { ContactForm } from './ContactForm';
// import PaperPlane from '../assets/PaperPlane.svg';

export function Contacts() {
    return (
        <div id="contact">
            <div className="bg-custom-background-white flex flex-row mx-[10%] justify-center">
                <div className='bg-custom-bright-white flex sm:flex-row flex-col border-[0.5px] rounded-button sm:p-10 px-5 pb-5 w-full '>
                    <div className='w-full sm:pt-[0%] pt-[5%]'>
                        <ContactForm />
                    </div>
                </div>
                {/* <img src={PaperPlane} alr='plane' className='sm:w-[300px] absolute z-[2] top-[65%] left-[-15%] absolute sm:block hidden' />
            <img src={PaperPlane} alt='plane' className='sm:w-[300px] w-[200px] absolute z-[2] top-[95%] right-[70%] sm:hidden block' /> */}
            </div>
        </div>
    );
}