import React, { useState, useEffect } from "react";

export function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
        if (isNavOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        }
    }, [isNavOpen]);

    const mobileMenuButton = () => {
        document.body.style.overflow = 'unset';
        setIsNavOpen(false);
    }

    return (
        <div className="flex flex-row justify-between items-center px-[5%] bg-custom-background-white lg:h-[10vh] h-[8vh] md:border-b-[1px] border-b-[0.8px] border-custom-orange">
            <a href="/">
                <div className='flex-col justify-start items-center'><p className='font-custom-r sm:text-1xl text-md text-custom-black'>WM Supernova</p>
                    <p className='font-custom-r sm:text-1xl text-md text-custom-black pl-[50%]'>Digital</p>
                </div>
            </a>
            <nav>
                <section className="MOBILE-MENU flex md:hidden bg-custom-background-white">
                    <div
                        className="HAMBURGER-ICON space-y-2 z-[50] cursor-pointer"
                        onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                        <span className="block h-0.5 w-8 animate-pulse bg-custom-black hover:text-custom-dark-grey"></span>
                        <span className="block h-0.5 w-8 animate-pulse bg-custom-black hover:text-custom-dark-grey"></span>
                        <span className="block h-0.5 w-8 animate-pulse bg-custom-black hover:text-custom-dark-grey"></span>
                    </div>

                    <div className={isNavOpen ? "showMenuNav z-[51]" : "hideMenuNav"}>
                        <div
                            className="bg-custom-background-white absolute top-0 right-0 px-8 py-8"
                            onClick={() => setIsNavOpen(false)}
                        >
                            <svg
                                className="h-8 w-8 text-gray-600"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </div>
                        <ul className="flex flex-col items-center justify-between min-h-[250px]">
                            <li className=" my-8 uppercase font-custom-r text-custom-black cursor-pointer hover:text-custom-dark-grey">
                                <a onClick={mobileMenuButton} href="/">Home</a>
                            </li>
                            <li className=" my-8 uppercase font-custom-r text-custom-black cursor-pointer hover:text-custom-dark-grey">
                                <a onClick={mobileMenuButton} href="#services">Services</a>
                            </li>
                            <li className=" my-8 uppercase font-custom-r text-custom-black cursor-pointer hover:text-custom-dark-grey">
                                <a onClick={mobileMenuButton} href="#projects">Projects</a>
                            </li>
                            <li className="my-8 uppercase font-custom-r text-custom-black cursor-pointer hover:text-custom-dark-grey">
                                <a onClick={mobileMenuButton} href="#contact" >Contact</a>
                            </li>
                        </ul>
                    </div>
                </section>

                <ul className="DESKTOP-MENU hidden space-x-2 md:flex items-end justify-between">
                    <li className="font-custom-r sm:text-1xl text-md text-custom-black cursor-pointer hover:text-custom-dark-grey z-[50]"><a href="#services" >Services</a></li>
                    <li className="font-custom-r text-1xl sm:text-1xl text-md text-custom-black px-4 cursor-pointer hover:text-custom-dark-grey z-[50]"><a href="#projects" >Projects</a></li>
                    <li className="font-custom-r sm:text-1xl text-md text-custom-black cursor-pointer hover:text-custom-dark-grey z-[50]"><a href="#contact" >Contact</a></li>
                </ul>
            </nav>
            <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: #F8F8F8;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
        </div>
    );
}
