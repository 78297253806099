//import logo from './logo.svg';
import './App.css';

import React from 'react';

import { Header } from './components/Header';
import { HomeScreen } from './components/HomeScreen';
import { ListOfServices } from './components/ListOfServices';
import { CompaniesWeWorkedWith } from './components/CompaniesWeWorkedWith';
import { WhyChooseUs } from './components/WhyChooseUs';
import { OurProjects } from './components/OurProjects';
import { StagesOfWorkingWithhUs } from './components/StagesOfWorkingWithhUs';
import { Contacts } from './components/Contacts';
import { Footer } from './components/Footer';

import { GetInTouchButton } from './components/GetInTouchButton';
import Orange2 from './assets/Ellipse2.png';

function App() {
  return (
    <div className='overflow-hidden'>
      <div className='bg-custom-background-white'>
        <Header />
        <HomeScreen />
        <div className='lg:pt-[0%] xs:pt-[2%] pt-[5%]'>
          <ListOfServices />
        </div>
        <div className='lg:pt-[2%] lg:pb-[8%] pt-[5%] pb-[8%]'>
          <GetInTouchButton />
        </div>
        <div className='lg:p-0 xs:pt-[3%] pt-[15%]'>
          <CompaniesWeWorkedWith />
        </div>
        <div className='relative '>
        <div className='lg:pt-[3%] xs:pt-[8%] pt-[16%]'>
            <WhyChooseUs />
          </div>
          <img src={Orange2} alt="orange" className='w-[600px] absolute z-[2] lg:top-[3%] lg:right-[75%] xs:top-[3%] xs:right-[65%] top-[3%] right-[50%] ' />
          <div className='xs:pt-0 pt-[10%]'>
            <OurProjects />
          </div>
          <div className='lg:pt-[2%] lg:pb-[8%] pt-[5%] pb-[10%]'>
            <GetInTouchButton />
          </div>
          <img src={Orange2} alt="orange" className='w-[600px] absolute z-[2] lg:bottom-[25%] lg:left-[75%] xs:bottom-[30%] xs:left-[65%] bottom-[30%] left-[55%] ' />
          <div className='xs:pt-0 pt-[10%]'>
            <StagesOfWorkingWithhUs />
          </div>
        </div>
        <div className='lg:py-[5%] xs:py-[10%] py-[15%]'>
          <Contacts />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
