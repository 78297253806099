import Lenovo from '../assets/Lenovo.svg';
import Unilever from '../assets/Unilever.svg';
import InkMasterpiece from '../assets/InkMasterpiece.svg';
import PierreFabre from '../assets/PierreFabre.svg';
import Bonduelle from '../assets/Bonduelle.svg';
import MAPOGuides from '../assets/MAPOGuides.svg';
import Turkcell from '../assets/Turkcell.svg';

export function CompaniesWeWorkedWith() {
    return (
        <div>
            <div className="bg-custom-background-white flex flex-col mx-[10%]">
                <div className='flex flex-col items-start '>
                    <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-2xl text-custom-orange mb-1 text-center ">
                        COMPANIES
                    </p>
                    <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-2xl text-custom-orange text-center pl-[5%]">
                        WE WORKED WITH
                    </p>
                </div>
                    <div className='flex sm:flex-row flex-col flex-wrap justify-center items-center xs:mt-[5%] mt-[8%]'>
                            <img src={Lenovo} className='xl:w-[150px] w-[100px] sm:my-[0%] my-[5%]' />
                            <img src={Unilever} className='xl:w-[90px] w-[80px] sm:mx-[7%] mx-[0%] sm:my-[0%] my-[10%]' />
                            <img src={InkMasterpiece} className='xl:w-[110px] w-[80px]' />
                            <img src={PierreFabre} className='xl:w-[200px] w-[150px] sm:mx-[3%] mx-[0%] my-[0%]' />
                            <img src={MAPOGuides} className='xl:w-[100px] w-[80px]' />
                            <img src={Bonduelle} className='xl:w-[195px] sm:w-[150px] w-[120px] sm:mx-[3%] mx-[0%] sm:my-[0%] mt-[3%]' />
                        <img src={Turkcell} className='xl:w-[200px] w-[160px]' />
                    </div>
            </div>
        </div>
    );
}