import { useEffect } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import image from '../assets/clean keepers.png';

export function CleanKeepers({ onClose }) {
    useEffect(() => {
        // Add the no-scroll class to the body when the modal is opened
        document.body.classList.add('no-scroll');

        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={onClose}>
            <div className=" p-6 rounded-lg shadow-lg relative max-h-[90vh] overflow-y-auto">
                <IoCloseOutline
                    className="sticky top-0 float-right sm:text-3xl text-2xl text-custom-bright-white hover:text-custom-bright-white cursor-pointer z-51"
                    onClick={onClose}
                />
                <div className="flex flex-col justify-center items-center mb-4">
                    <img
                        src={image}
                        alt="website"
                        className="sm:w-1/2 w-full"
                    />
                </div>
            </div>
        </div>
    );
}
