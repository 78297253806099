export function GetInTouchButton() {
    return (
        <div>
            <div className="bg-custom-background-white flex flex-row mx-[10%] relative justify-center">
                <a className="w-full z-[50]" href="#contact">
                    <button className="text-1xl text-custom-bright-white bg-custom-orange w-full rounded-button md:py-[14px] py-[10px] mt-4">
                        GET IN TOUCH
                    </button>
                </a>
            </div>
        </div>
    );
}
