import React, { useState } from 'react';
import ArrowButton from "../assets/ArrowButton.svg";
import Orange2 from '../assets/Ellipse2.png';

export function ListOfServices() {
    const [isHovered, setIsHovered] = useState([false, false, false, false, false, false, false, false]);

    const handleMouseEnter = (index) => {
        const newIsHovered = [...isHovered];
        newIsHovered[index] = true;
        setIsHovered(newIsHovered);
    }

    const handleMouseLeave = (index) => {
        const newIsHovered = [...isHovered];
        newIsHovered[index] = false;
        setIsHovered(newIsHovered);
    }
    return (
        <div id="services">
            <div className="bg-custom-background-white flex flex-col mx-[10%] relative">
                <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-2xl text-custom-orange mb-[2%] text-center self-start">
                    WHAT DO WE DO
                </p>

                <div className="flex flex-col border-t-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                01
                            </p>
                            <div className="flex flex-col sm:mx-10 xs:mx-8 mx-4 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r xs: xs:text-2xl text-xl text-xl text-custom-black pb-2">
                                    Branding
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                    Whether starting a new business or need a redesign, we can help setting your company new brand image and style.
                                </p>
                            </div>
                        </div>
                        {/* <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(0)}
                            onMouseLeave={() => handleMouseLeave(0)}
                        >
                            {isHovered[0] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">CONTACT US</p>
                            ) : (
                                <img
                                src={ArrowButton}
                                alt="button"
                                className="w-[40px] mr-6 self-end"
                                />
                            )}
                        </div> */}
                    </div>
                </div>

                <div className="flex flex-col border-t-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                02
                            </p>
                            <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                    Website design
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                    Creating a customised design for your website.
                                </p>
                            </div>
                        </div>
                        {/* <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={() => handleMouseLeave(1)}
                        >
                            {isHovered[1] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">CONTACT US</p>
                            ) : (
                                <img
                                src={ArrowButton}
                                alt="button"
                                className="w-[40px] mr-6 self-end"
                                />
                            )}
                        </div> */}
                    </div>
                </div>

                <div className="flex flex-col border-t-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                03
                            </p>
                            <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                    Web development
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                    Developing a website based on the created website design. Or,
                                    improving your current website.
                                </p>
                            </div>
                        </div>
                        {/* <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(2)}
                            onMouseLeave={() => handleMouseLeave(2)}
                        >
                            {isHovered[2] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">CONTACT US</p>
                            ) : (
                                <img
                                src={ArrowButton}
                                alt="button"
                                className="w-[40px] mr-6 self-end"
                                />
                            )}
                        </div> */}
                    </div>
                </div>

                <div className="flex flex-col border-t-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                04
                            </p>
                            <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                    Social media strategy development
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                    Building a tailor-made strategy to promote your business on
                                    social media.
                                </p>
                            </div>
                        </div>
                        {/* <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(3)}
                            onMouseLeave={() => handleMouseLeave(3)}
                        >
                            {isHovered[3] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">CONTACT US</p>
                            ) : (
                                <img
                                src={ArrowButton}
                                alt="button"
                                className="w-[40px] mr-6 self-end"
                                />
                            )}
                        </div> */}
                    </div>
                </div>

                <div className="flex flex-col border-t-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                05
                            </p>
                            <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                    Social media management
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                    Saving your time, by letting us to manage your social media
                                    accounts.
                                </p>
                            </div>
                        </div>
                        {/* <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(4)}
                            onMouseLeave={() => handleMouseLeave(4)}
                        >
                            {isHovered[4] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">CONTACT US</p>
                            ) : (
                                <img
                                src={ArrowButton}
                                alt="button"
                                className="w-[40px] mr-6 self-end"
                                />
                            )}
                        </div> */}
                    </div>
                </div>

                <div className="flex flex-col border-t-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                06
                            </p>
                            <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                    Advertisements
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                    Launching paid promotional campaigns on Meta, TikTok, Pinterest,
                                    Snapchat, YouTube. Also, Search Engine Marketing, Google search
                                    and display network.
                                </p>
                            </div>
                        </div>
                        {/* <div
                            className="self-end justify-end flex h-1/2 cursor-pointer pl-10"
                            onMouseEnter={() => handleMouseEnter(5)}
                            onMouseLeave={() => handleMouseLeave(5)}
                        >
                            {isHovered[5] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">CONTACT US</p>
                            ) : (
                                <img
                                src={ArrowButton}
                                alt="button"
                                className="w-[40px] mr-6 self-end"
                                />
                            )}
                        </div> */}
                    </div>
                </div>

                <div className="flex flex-col border-t-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                07
                            </p>
                            <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                    Content creation
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                    Creating content such as photos, videos, visuals.
                                </p>
                            </div>
                        </div>
                        {/* <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(6)}
                            onMouseLeave={() => handleMouseLeave(6)}
                        >
                            {isHovered[6] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">CONTACT US</p>
                            ) : (
                                <img
                                src={ArrowButton}
                                alt="button"
                                className="w-[40px] mr-6 self-end"
                                />
                            )}
                        </div> */}
                    </div>
                </div>

                <div className="flex flex-col border-t-2  border-b-2">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                            <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                08
                            </p>
                            <div className="flex flex-col  sm:mx-10 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r font-light  xs:text-2xl text-xl text-custom-black pb-2">
                                    Audit
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                    An assessment to gain insights for optimisation of your current
                                    website, social media account or advertisements.
                                </p>
                            </div>
                        </div>
                        {/* <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(7)}
                            onMouseLeave={() => handleMouseLeave(7)}
                        >
                            {isHovered[7] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">CONTACT US</p>
                            ) : (
                                <img
                                src={ArrowButton}
                                alt="button"
                                className="w-[40px] mr-6 self-end"
                                />
                            )}
                        </div> */}
                    </div>
                </div>
                <img src={Orange2} alt="orange" className='w-[500px] absolute z-[2] lg:top-[80%] lg:right-[85%] xs:bottom-[-30%] xs:right-[75%] bottom-[0%] right-[55%]' />
            </div>
        </div>
    );
}
