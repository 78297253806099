// import Orange2 from '../assets/Ellipse2.png';

export function StagesOfWorkingWithhUs() {
  return (
<div>
            <div className="bg-custom-background-white flex flex-col mx-[10%] relative">
                <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-2xl text-custom-orange mb-1 text-center self-start">
                    STAGES OF
                </p>
                <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-2xl text-custom-orange text-center self-start pl-[5%]">
                    WORKING WITH US
                </p>

                <div className="flex flex-col border-t-2 mt-8">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                        <p className="tracking-wide font-custom-m font-light xs:text-2xl text-xl text-custom-dark-grey ">
                                01
                            </p>
                            <div className="flex flex-col sm:mx-10 xs:mx-8 mx-4 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r xs: xs:text-2xl text-xl text-xl text-custom-black pb-2">
                                    Contact
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                After you get in touch, we schedule a free introductory consultation to better understand your needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col border-t-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                        <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                02
                            </p>
                            <div className="flex flex-col sm:mx-10 xs:mx-8 mx-4 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r xs: xs:text-2xl text-xl text-xl text-custom-black pb-2">
                                    Organisation
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                Signing the contract, getting access, creating a work chat group on WhatsApp.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col border-t-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                        <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                03
                            </p>
                            <div className="flex flex-col sm:mx-10 xs:mx-8 mx-4 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r xs: xs:text-2xl text-xl text-xl text-custom-black pb-2">
                                Preparation
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                Carrying out research and creating a strategy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col border-t-2 border-b-2  ">
                    <div className="flex flex-row py-6 justify-between ">
                        <div className="flex flex-row ">
                        <p className="tracking-wide font-custom-m font-light  xs:text-2xl text-xl text-custom-dark-grey ">
                                04
                            </p>
                            <div className="flex flex-col sm:mx-10 xs:mx-8 mx-4 xs:mx-8 mx-4">
                                <p className="tracking-wide font-custom-r xs: xs:text-2xl text-xl text-xl text-custom-black pb-2">
                                Execution
                                </p>
                                <p className="tracking-wide font-custom-r font-light text-1xl text-custom-light-black">
                                Executing the strategy, testing and adjusting.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                
                {/* <img src={Orange2} alt="orange" className='w-[500px] absolute z-[2] top-[-30vh] right-[-40vh]' /> */}
            </div>
        </div>
  );
}