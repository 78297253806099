export function Footer() {
    return (
        <div className="flex flex-row w-full justify-between bg-custom-black px-[10%] py-10">
            <div className='flex flex-col justify-between  w-full items-start'>

                <div className='flex-col '>
                    <a href="/">
                        <p className='font-custom-r text-1xl text-custom-orange'>WM Supernova</p>
                        <p className='font-custom-r text-1xl text-custom-orange pl-[50%] pb-5'>Digital</p>
                    </a>
                </div>

                <div>
                    <p className='font-custom-r text-1xl text-custom-dark-grey '>2024</p>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="flex flex-col w-full sm:items-center items-end">
                    <a href='#services' className="font-custom-r text-1xl text-custom-background-white my-[10%] sm:my-[7%] mx-0">Services</a>
                    <a href='#projects' className="font-custom-r text-1xl text-custom-background-white my-[10%] sm:my-[7%] mx-0">Projects</a>
                    <a href='#contact' className="font-custom-r text-1xl text-custom-background-white my-[10%] sm:my-[7%] mx-0">Contact</a>
                </div>
            </div>

        </div>
    );
}